@import 'ember-paper';

md-toolbar.md-default-theme.page-main-toolbar {
  background-color: color('grey', '400');
  color: $dark-contrast-color;
  position:fixed;
  width:100%;
  max-width:1240;
  left:0px;
  top:0px;

  md-icon, .md-button md-icon {
    color: $dark-contrast-color;
    margin-top: -2px;
  }
}

/* Overlaying an image over video. */
#videocontainer {
  position: relative;
}
#navi,
#infoi {
  position: absolute;
  top: 300px;
  left: 0;
}
#infoi {
  z-index: 10;
}